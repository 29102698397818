import "bootstrap-icons/font/bootstrap-icons.css";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from 'react';
import "./closecase.scss";

export const CloseCase = ({ referrer, label = "Close" }) => {
    const { t } = useTranslation("common", { keyPrefix: "header" })
    return (
        <a href={referrer ?? '/'} className="container"><i className="bi bi-x-circle" /><span className="closeText">{t(label)}</span></a>
    )
}
