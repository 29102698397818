import { graphql } from 'gatsby'
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Row } from "react-bootstrap"
import FindDreamJob from "../components/careers/finddreamjob"
import AiSecurity from "../components/cases/aiSecurity"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import "./mainpage.scss"

const IndexPage = ({location}) => {
  const { t, i18n } = useTranslation("aisecurity")
  return (
    <Layout>
      <div className="max-container-pages">
        <Seo  title={t("title")} lang={i18n.resolvedLanguage} description={t("seo.description", { ns: 'common' })} />
        <Row>
          <AiSecurity previous={location.state?.from ?? '/'} ></AiSecurity>
          <FindDreamJob></FindDreamJob>
        </Row>
      </div>
    </Layout>
  )
}

export default IndexPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "aisecurity"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
